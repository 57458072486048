<template>
   <div class="categorias">
        <div :class="{ 'item card': true, 'activo': categoriaActiva === 'cafe' }">
            <span class="material-symbols-outlined">coffee</span>
            <span @click="filtro('cafe')">Café</span>
        </div>
        <div :class="{ 'item card': true, 'activo': categoriaActiva === 'tortas' }">
            <span class="material-symbols-outlined">cake</span>
            <span @click="filtro('tortas')">Tortas</span>
        </div>
        <div :class="{ 'item card': true, 'activo': categoriaActiva === 'salados' }">
            <span class="material-symbols-outlined">breakfast_dining</span>
            <span @click="filtro('salados')">Salados</span>
        </div>
        <!-- <div :class="{ 'item card': true, 'activo': categoriaActiva === 'saludable' }">
            <span class="material-symbols-outlined">coffee</span>
            <span @click="filtro('saludable')">Saludable</span>
        </div> -->
        
        <div :class="{ 'item card': true, 'activo': categoriaActiva === 'bebidas' }">
            <span class="material-symbols-outlined">grocery</span>
            <span @click="filtro('bebidas')">Bebidas</span>
        </div>
        <div :class="{ 'item card': true, 'activo': categoriaActiva === 'combos' }">
            <span class="material-symbols-outlined">multicooker</span>
            <span @click="filtro('combos')">Combos</span>
        </div>
        <div :class="{ 'item card': true, 'activo': categoriaActiva === 'dulces' }">
            <span class="material-symbols-outlined">bakery_dining</span>
            <span @click="filtro('dulces')">Dulces</span>
        </div>
        <div :class="{ 'item card': true, 'activo': categoriaActiva === 'tartas' }">
            <span class="material-symbols-outlined">cake</span>
            <span @click="filtro('tartas')">Tartas</span>
        </div>
        <div :class="{ 'item card': true, 'activo': categoriaActiva === 'sandwiches' }">
            <span class="material-symbols-outlined">lunch_dining</span>
            <span @click="filtro('sandwiches')">Sandwiches</span>
        </div>
        <div :class="{ 'item card': true, 'activo': categoriaActiva === 'desayunos' }">
            <span class="material-symbols-outlined">coffee</span>
            <span @click="filtro('desayunos')">Desayunos</span>
        </div>
        <div :class="{ 'item card': true, 'activo': categoriaActiva === '' }">
            <span class="material-symbols-outlined">menu_book</span>
            <span @click="filtro('')">Mostrar todo</span>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { ref } from 'vue';
export default {
    setup(){
        const store = useStore()
        const categoriaActiva = ref('cafe');
        const filtro = (categoria) => {
            store.dispatch('filtrarCategoria', categoria);
            categoriaActiva.value = categoria;
        }
        return {filtro, categoriaActiva}
    }
}
</script>

<style scoped>
.categorias{
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    overflow-x: auto; 
    white-space: nowrap; 
    -webkit-overflow-scrolling: touch; 
}

.item.card {
    display:flex;
    flex-direction: row;  
    padding:5px;
    margin: 5px 3px;
    align-items: center;
    border-radius: 15px; 
    min-width: auto;
}
.activo {
  background-color: #7cdad3;
}
span{
    font-size:small;
}
.material-symbols-outlined {
  font-size: 15px; 
  margin-right: 2px; 
}

</style>