import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Contacto from '../views/Contacto.vue'
import Pedidos from '../views/Pedidos.vue'
import Carta from '../views/Carta.vue'
import CarritoView from '../views/CarritoView.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/carta',
    name: 'carta',
    component: Carta
  },
  {
    path: '/pedidos',
    name: 'pedidos',
    component: Pedidos
  },
  {
    path: '/carrito',
    name: 'carrito',
    component: CarritoView
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: Contacto
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
