<template>
    <h3 class="mt-3">Menú</h3>
    <div class="seccion-buscador mb-2">
      <span class="buscador-input ">
        <input
          type="text"
          placeholder="Buscar producto"
          class="form-control"
          v-model="texto"
          @input="procesarInput"
        />
      </span>
      <span class="material-symbols-outlined buscador-icono">
        search
      </span>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
export default {
    setup(){
        const texto = ref('')
        const store = useStore()

        const procesarInput = () => {
            store.dispatch('filtroBuscador', texto.value)
        }
        return {texto, procesarInput}
    }
}
</script>
<style scoped>
.buscador-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f4f4f4;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000; 
}

.buscador-input {
  position: relative;
  width: 100%;
}

.buscador-input input {
  padding-left: 30px;
}

.buscador-icono {
  position: absolute;
  left: auto;
  padding-left: 5px;
  color: #6e6e6e;
  font-size: medium;
}
.seccion-buscador {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
}

h3 {
  font-family: 'Be Vietnam Pro', sans-serif;
}
input{
    font-size: small;
}

</style>