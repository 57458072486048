<template>
  <div class="home">
    <div class="imagenPrincipal">
      <img src="../assets/logo-principal.jpg" alt="logo-tia-maria" class="logoPrincipal">
    </div>
    <div class="containerPrincipal">
      <br>
      <h3 style="color: white; font-weight: 600;">~ ¡Hoy es un lindo día para que nos visites! ~</h3>
      <br>
      <div class="ubicacion">
        <span class="material-symbols-outlined text-white">
          location_on
        </span>
        <span><h4 style="font-weight: 600;">Sarmiento 866, entre Libertad y 12 de Octubre</h4></span>
      </div>
      <hr>
      <h4 style="font-weight: 600; font-size: large;">HORARIOS DE ATENCIÓN</h4>
      <!-- <h4 style="color: #245e5a; font-weight: 700; font-size: xx-large;">ABIERTO</h4> -->
      <hr>
      <h4 style="font-weight: 600;">LUNES</h4>
      <h4>de 16:30 a 20:30 hs</h4>
      <br>
      <h4 style="font-weight: 600;">MARTES A SÁBADOS</h4>
      <h4>de 8:00 a 12:00 hs y</h4>
      <h4>de 16:30 a 20:30 hs</h4>
      <br>
      <div class="navegacion">
        <div>
          <!-- <span class="material-symbols-outlined">
            edit_note
          </span> -->
          <span class="material-symbols-outlined">
          event_note
          </span>
          <!-- <span class="material-symbols-outlined">
            menu_book
          </span> -->
          <router-link to="/carta" class="nav-link">
            <h4 class="navegacionTitulo">CARTA</h4>
          </router-link>
        </div>
        <div>
          <span class="material-symbols-outlined">
            add_shopping_cart
          </span>
          <router-link to="/pedidos" class="nav-link">
            <h4 class="navegacionTitulo">PEDIDOS ONLINE</h4>
          </router-link>
        </div>
        <div>
          <span class="material-symbols-outlined">
            person
          </span>
          <router-link to="/contacto" class="nav-link">
            <h4 class="navegacionTitulo">CONTACTO</h4>
          </router-link>
        </div>
      </div>
      <br>
    <Footer />
    </div>
  </div>
</template>

<script>
import Footer from '../components/Footer'
export default {
  name: 'HomeView',
  components: {
    Footer
  }
}
</script>

<style scoped>
.home{
  height: 100vh;
  text-align: center;
}
.imagenPrincipal{
  height: 40vh;
  text-align: center;
}
.logoPrincipal{
  padding: 0;
  height: 40vh;
}
.containerPrincipal{
  padding: 0;
  width: 100%;
  height: 60vh;
  background-color: #4ba8a2;
  /* border-top-left-radius: 100px; */
  /* border-top-right-radius: 100px; */
}
.navegacion{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.navegacionTitulo{
  background-color: #245e5a;
  color: white;
  padding: 5px;
  border-radius: 10px;
}
h4{
  color: white;
  font-size: medium;
}
h3{
  font-size: large;
}
.ubicacion{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

</style>
