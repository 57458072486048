<template>
    <div class="col">
        <div class="card mb-2 ">
            <div class="container-img text-center">
                <img :src="producto.imagen" class="card-img-top w-80" alt="...">
            </div>
            <div class="card-body">
                <h5 class="card-title text-center">{{ producto.titulo }}</h5>
                <p class="card-text">{{ producto.descripcion }}</p>
            </div>
            <div class="footer p-10">
                <p class="precio">${{ producto.precio }}</p>
                <span class="boton-añadir" :class="{ 'active': clicked }" @click="agregar(producto)">Agregar al carro</span>
            </div>
        </div>
        <p class="text-center p-0" v-if="mensajeVisible">{{ mensaje }}<span class="material-symbols-outlined mt-4">
            done_outline
        </span></p>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
export default {
    props: ['producto'],
    setup(){
        const store = useStore()
        const clicked = ref(false);
        const mensaje = ref('Se agregó correctamente');
        const mensajeVisible = ref(false);
        const agregar = (producto) => {
            store.dispatch('agregarAlCarro', producto)
            clicked.value = !clicked.value;
            mensajeVisible.value = true;
            if (clicked.value) {
                setTimeout(() => {
                clicked.value = false;
                mensajeVisible.value = false;
                }, 2000); 
            }
        }
        return {agregar, clicked, mensaje, mensajeVisible}
    }
}
</script>

<style scoped>
.footer{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}
.boton-añadir{
    background-color: #7cdad3;
    border-radius: 3px;
    padding-top: 10px;
    padding: auto;
    padding-left: 5px;
    padding-right: 5px;
    transition: background-color 0.3s;
}

.boton-añadir.active{
    background-color: #2c6460;
    color: aliceblue;
}
.precio{
    padding-left: 10px;
    padding-top: 5px;
    font-size: large;
    font-weight: bold;
}
h5{
    font-size:large;
    font-weight:bold;
}
p{
    font-size: 13px;
}
span{
    font-size: small;
}
</style>