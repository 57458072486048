<template>
  <div>
    <Navbar />
    <div class="container">
      <Buscador />
      <Categoria />
      <br>
      <div v-if="!imagesLoaded" class="loading-indicator">
        <img src="../assets/cargando.gif" width="50" height="50" alt="Cargando..." />
      </div>

      <div v-else class="row row-cols-2 row-cols-md-3 row-cols-lg-6 g-4">
        <Card v-for="producto in productos" :key="producto.id" :producto="producto" />
      </div>
    </div>
    <br>
    <Footer />
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import Navbar from '../components/Navbar.vue';
import Card from '../components/Card.vue';
import Categoria from '../components/Categoria.vue';
import Buscador from '../components/Buscador.vue';
import { useStore } from 'vuex';
import Footer from '../components/Footer.vue';

export default {
  components: {
    Navbar, Card, Categoria, Buscador, Footer
  }, 
  setup() {
    const store = useStore();
    const productos = computed(() => store.state.productosFiltrados);
    const imagesLoaded = ref(false);

    const cargarProductos = async (categoria) => {
      imagesLoaded.value = false;
      await store.dispatch('filtrarCategoria', categoria);

      // Simulación de carga de imágenes con async/await
      await new Promise(resolve => setTimeout(resolve, 2000)); // Ajusta el tiempo según sea necesario

      imagesLoaded.value = true;
    };

    onMounted(async () => {
      await store.dispatch('getProductos');
      await cargarProductos('cafe');
    });

    return { productos, imagesLoaded, cargarProductos };
  }
};
</script>

<style scoped>
.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Ajusta según sea necesario */
}
</style>
