<template>
    <div class="container-footer p-2 text-center">
        
        <h5 class="text-white">¿Tenés alguna duda? Comunicate con nosotros.</h5>
        <a href="https://wa.me/5493718410081"><span class="mx-4"><img src="../assets/logo-whats.png" width="35" height="35"></span></a>
        <a href="https://www.instagram.com/tiamariapaste2/"><span><img src="../assets/instagram.png" alt="" width="35" height="35"></span></a>
        <p class="mt-3 text-white">© 2023 Tía María Pastelería - Todos los derechos reservados | Sebastián Rodas</p>       
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.container-footer{
    background-color: #245e5a;
}
h5{
    font-size: medium;
}
p{
    font-size: small;
}
</style>
