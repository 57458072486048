<template>
  <div class="about">
  <Navbar/>
  <div class="contacto container my-2">
    <div class="row">
      <div class="col-12 col-md-6  my-2 text-center">
        <hr>
        <h3>Horarios de Atención</h3>
        <hr>
        <h4>LUNES</h4>
        <h4>de 16:30 a 20:30 hs</h4>
        <br>
        <h4>MARTES A SÁBADOS</h4>
        <h4>de 8:00 a 12:00 hs y</h4>
        <h4>de 16:30 a 20:30 hs</h4>
        <br>
      </div>
      <div class="col-12 col-md-6 my-2 text-center">
        <h3>Ubicación</h3>
        <div class="ubicacion">
          <span class="material-symbols-outlined ">
            location_on
          </span>
          <span><h5>Sarmiento 866, entre Libertad y 12 de Octubre</h5></span>
        </div>
        <div v-html="mapCode"></div>
      </div>
    </div>
  </div>
  </div>
  <Footer />
</template>

<script>
import Navbar from '../components/Navbar.vue'
import Footer from '../components/Footer.vue'
export default {
  components:{
    Navbar, Footer
  },
  data() {
    return {
      mapCode: `
      <iframe src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d2376.980375662139!2d-57.71773841617889!3d-25.28885283577571!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d-25.2881178!2d-57.716296299999996!4m5!1s0x945d09143084b8c9%3A0x656c2a746e3d680c!2sSarmiento%20866%20P3610GJR%2C%20P3610%20Clorinda%2C%20Formosa!3m2!1d-25.2882121!2d-57.716291!5e0!3m2!1ses!2sar!4v1701116405723!5m2!1ses!2sar" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      `
    };
  }
}
</script>
<style scoped>

.ubicacion{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
h3{
  font-size:x-large
}
h4{
  font-size: large;
}
h5{
  font-size: medium;
}
@media (min-width: 600px){
  .about{
  height: 81vh;
}
}
</style>