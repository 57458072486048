<template>
    <!-- <nav class="navbar navbar-expand-lg">
    
        <div class="container-fluid ">
            <router-link to="/" class="navbar-brand" @click="closeNavbar"><img src="https://firebasestorage.googleapis.com/v0/b/tia-maria-pasteleria.appspot.com/o/Logo%20Circular%20Tia%20Maria%20_Mesa%20de%20trabajo%201%20copia%2042.png?alt=media&token=11a4d84f-5d77-45b3-a78e-1acc150fe5da"></router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul class="navbar-nav">
                <li class="nav-item">
                <router-link to="/carta" class="nav-link" @click="closeNavbar">Carta</router-link>
                </li>
                <li class="nav-item">
                <router-link to="/pedidos" class="nav-link" @click="closeNavbar">Pedidos</router-link>
                </li>
                <li class="nav-item">
                <router-link to="/contacto" class="nav-link" @click="closeNavbar">Contacto</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/carrito" class="nav-link" @click="closeNavbar"><span class="material-symbols-outlined">
                    shopping_cart_checkout
                  </span></router-link>
                </li>
            </ul>
            </div>
        </div>
    </nav> -->
    <nav>
      <ul class="nav">
        <li class="nav-item">
          <router-link to="/" class="navbar-brand"><img src="https://firebasestorage.googleapis.com/v0/b/tia-maria-pasteleria.appspot.com/o/Logo%20Circular%20Tia%20Maria%20_Mesa%20de%20trabajo%201%20copia%2042.png?alt=media&token=11a4d84f-5d77-45b3-a78e-1acc150fe5da"></router-link>
        </li>
        <li class="nav-item">
          <router-link to="/carrito" class="nav-link">
            <span v-if="totalCantidad > 0" class="item-carrito">{{ totalCantidad }}</span>
            <span class="material-symbols-outlined px-1">shopping_cart_checkout</span>
          </router-link>
        </li>
      </ul>
    </nav>
</template>
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  methods: {
    closeNavbar() {
      let navbar = document.querySelector('.navbar-collapse');
      if (navbar.classList.contains('show')) {
        navbar.classList.remove('show');
      }
    }
  },
  setup(){
    const store = useStore()
    const totalCantidad = computed(()=> store.getters.totalCantidad)
    return {totalCantidad}
  }
}
</script>
<style scoped>
.navbar-brand img {
  width: 50px; /* Ajusta el valor según sea necesario */
  height: auto;
}
.navbar-brand {
  margin: 0;
  padding: 0;
}
nav{
  background-color: rgb(231, 166, 176);
  /* background-color: #245e5a; */
  /* background-color: #7cdad3; */
}
.nav{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 5px 0 5px;
  padding: 2px;
}
.nav-link{
  display: flex;
  font-size: medium;
  color: black;
  align-items: center;
}

span{
  font-size:large;
  padding-top: 5px;
}

</style>