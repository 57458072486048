<template>
    <div class="card mb-3 cart-container p-0 ">
        <div class="cart-img">
            <img :src="item.imagen" class="img-fluid rounded-start w-100 h-100" alt="...">
        </div>
        <div class="cart-item">
            <h5 class="card-title">{{ item.titulo }}</h5>
            <p class="card-text">{{ item.descripcion }}</p>
            <p class="card-text total">Total: ${{item.precio * item.cantidad}}</p>
        </div>
        <div class="cart-button">
            <button class="btn btn-color btn-sm mx-2" @click="aumentar(item.id)">
                +
            </button>
            <p class="pt-3 item-cantidad">{{ item.cantidad }}</p>
            <button class="btn btn-color btn-sm" @click="disminuir(item.id)">
                -
            </button>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex';

    export default {
        props: ['item'],
        setup(){
            const store = useStore()
            const aumentar = (id) => {store.commit('aumentar', id)}
            const disminuir = (id) => {store.commit('disminuir', id)}
            return {aumentar, disminuir}
        }
    }
</script>

<style scoped>
.cart-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-right: 100px;
}
.cart-img{
    width: 30%;
}
.cart-item{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin-left: 10px;
   
}
.cart-button{ 
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content:  space-around;
    align-items: center;
    background-color: rgb(212, 148, 159);
    border-radius: 15px;
    padding: 1px 2px
 }
button{
    padding: 1px;
    font-size: medium;
    color: aliceblue;
}
.item-cantidad{
    color:aliceblue;
    font-size: small;
}
h5{
    font-size: large;
}
p{
    font-size: small;
}
.total{
    font-size: medium;
}
/* .btn-color{
    background-color: rgb(207, 129, 142);
} */
</style>