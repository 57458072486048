<template>
    <Navbar />
    <div class="container">
        <div class="row row-cols-1 row-cols-md-3 g-4 m-1">
            <Carrito 
                v-for="item in items" key="item.id" :item="item"
            />
        </div>
        <div class="cart-footer">
            <div v-if="Object.keys(items).length === 0" class="text-center m-50">
                <h5>Carrito vacío, si desea realizar una compra
                    <br>
                    <router-link to="/pedidos">Presione acá</router-link>
                </h5>
            </div>
            <div v-else>
            <br>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                    <button class="btn btn-danger btn-sm" id="vaciar-carrito" @click="vaciar">
                            Eliminar todos los productos
                    </button>
                </div>
                <br>
                <div class="item-fecha">
                    <label>
                    Ingrese la fecha a retirar:
                    <input type="date" v-model="fecha" />
                    </label>
                    <br>
                    
                </div>
                <label>
                    Ingrese la hora a retirar:
                    <input type="time" v-model="hora" />
                </label>

                <br />

                <label>
                    Seleccione medio de pago:
                    <select v-model="medioPago">
                    <option value="efectivo">Seleccione medio de pago</option>
                    <option value="mercado pago">Efectivo</option>
                    <option value="mercado pago">Mercado Pago</option>
                    <option value="tarjeta de débito">Tarjeta de débito</option>
                    <option value="tarjeta de crédito">Tarjeta de crédito</option>
                    </select>
                </label>
                <br />

                <label class="text-center">
                    <textarea v-model="aclaracion" cols="55" rows="4" placeholder="¿Alguna aclaración sobre el pedido?"></textarea>
                </label>
                <br />
                    <h5 class="text-center">TOTAL A PAGAR: ${{ totalPrecio }}</h5>
                <br>
                <div class="d-grid gap-2 d-md-flex justify-content-md-center">
                    <button class="btn btn-success me-md-2" type="button" @click="enviarPedidoWhatsapp">Enviar pedido por WhatsApp</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '../components/Navbar.vue'
import Carrito from '../components/Carrito.vue'
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
    export default {
        components: {
            Navbar, Carrito
        },
        setup() {
            const store = useStore();
            const items = computed(() => {
            return store.state.carrito;
            });
            const totalPrecio = computed(() => store.getters.totalPrecio);
            const vaciar = () => {
            store.commit('vaciarCarrito');
            };

            // Definición de variables reactivas
            const fecha = ref('');
            const hora = ref('');
            const medioPago = ref('');
            const aclaracion = ref('');

            // Método para construir el mensaje
            const construirMensaje = () => {
            let mensaje = "¡Hola! Me gustaría hacer un pedido:\n";

            for (const item of Object.values(items.value)) {
                mensaje += `${item.cantidad} - ${item.titulo} - $${item.precio}\n`;
            }

            mensaje += `Fecha a retirar: ${fecha.value}\n`;
            mensaje += `Hora a retirar: ${hora.value}\n`;
            mensaje += `Medio de pago: ${medioPago.value}\n`;
            mensaje += `Aclaración: ${aclaracion.value}\n`;
            mensaje += `Total a pagar: $${totalPrecio.value}\n`;

            return mensaje;
            };

            // Método para enviar el pedido por WhatsApp
            const enviarPedidoWhatsapp = () => {
            const numeroWhatsapp = "5493718410081";
            const mensaje = construirMensaje();
            
            const url = `https://api.whatsapp.com/send?phone=${numeroWhatsapp}&text=${encodeURIComponent(mensaje)}`;
            
            window.open(url, "_blank");

            // Reiniciar campos
            fecha.value = '';
            hora.value = '';
            medioPago.value = '';
            aclaracion.value = '';

            // Vaciar el carrito
            vaciar();
            };

            return { items, totalPrecio, vaciar, fecha, hora, medioPago, aclaracion, enviarPedidoWhatsapp };
        },
    }
</script>

<style scoped>
h5{
    font-size: large;
}
button{
    font-size: medium;
}
label {
  display: block;
  font:
    1rem 'Fira Sans',
    sans-serif;
    font-size: medium;
}

input,
label {
  margin: 0.4rem 0;
  font-size: medium;
}
.item-fecha{
    display: flex;
    flex-direction: row;
    align-items: center;
}
</style>