import { createStore } from 'vuex'

export default createStore({
  state: {
    productos: [],
    productosFiltrados: [],
    carrito: {}
  },
  getters: {
    totalCantidad(state){
      return Object.values(state.carrito).reduce((acc, {cantidad})=> acc + cantidad, 0)
    },
    totalPrecio(state){
      return Object.values(state.carrito).reduce((acc, {cantidad, precio})=> acc + precio * cantidad, 0)
    }
  },
  mutations: {
    setProductos(state, payload){
      state.productos = payload;
      console.log(state.productos)
    },
    setProductosFiltrados(state, payload){
      state.productosFiltrados = payload
    },
    setCarrito(state, payload){
      state.carrito[payload.id] = payload
    },
    vaciarCarrito(state){
      state.carrito = {}
    },
    aumentar(state, payload){
      state.carrito[payload].cantidad = state.carrito[payload].cantidad + 1
    }, 
    disminuir(state, payload){
      state.carrito[payload].cantidad = state.carrito[payload].cantidad - 1
      if (state.carrito[payload].cantidad === 0 ){
        delete state.carrito[payload]
      }
    }
  },
  actions: {
    async getProductos({commit}){
      try {
        const res = await fetch('api.json')
        const data = await res.json()
        commit('setProductos', data)
      } catch (error) {
        console.log(error)
      }
    },
    agregarAlCarro({commit, state}, producto){
      state.carrito.hasOwnProperty(producto.id) ? producto.cantidad = state.carrito[producto.id].cantidad + 1 : producto.cantidad = 1
      commit('setCarrito', producto)
    }, 
    filtrarCategoria({commit, state}, categoria){
      const filtro = state.productos.filter(producto => 
          producto.categoria.includes(categoria)
        ) 
      commit('setProductosFiltrados', filtro)  
    },
    filtroBuscador({commit, state}, texto){
      const textoBuscador = texto.toLowerCase()
      const filtro = state.productos.filter( producto => {
        const textoApi = producto.titulo.toLowerCase()
        if(textoApi.includes(textoBuscador)){
          return producto
        }
      })
      commit('setProductosFiltrados', filtro)
    }
  },
  modules: {
  }
})
